import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Fade from '../../components/Fade';
import Notification from '../../components/HomeBlocks/Notification';
import Seo from '../../components/Seo';
import { Button, Flex, Loader, Text, Wrapper } from '../../components/Ui';
import * as actionCreators from '../../store/actions';
import CmsComponent from '../../components/CmsComponent';
import ProgressBar from '../../components/ProgressBar';
const Home = props => {
  const isLogged = useSelector(state => state.auth.token);
  const slug = 'get';
  const games = useSelector(state => state.game.games);
  const dispatch = useDispatch();

  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  const {
    // viewerLoading,
    // viewer,
    // getViewer,
    // viewerError,
    getAllPromo,
    getHgPromo,
    loadinghgpromo,
    loadingallpromo,
    hgpromo,
    allpromos,
    errorhgpromo,
    errorallpromo,
    appConfig: {
      i18l,
      design: {
        template_props: {
          highlights,
          allpromo,
          highlights_columns,
          allpromo_columns,
          tags_columns,
        },
      },
    },
    // tag
    getPromoTag,
    tagsclient,
    loadingclienttags,
    errorclienttags,
    getPromoTagViewer,
    tagviewer,
    loadingtagviewer,
    errortagviewer,
  } = props;

  let contentHigh = null;
  let contentAll = null;
  let contentTag = null;
  let contentViewer = null;
  let contentTagViewer = null;
  let seo = null;

  if (Object.entries(i18l).length !== 0) {
    const {
      seo: { title, description, keywords },
    } = i18l;
    seo = <Seo title={title} description={description} keywords={keywords} image={''} />;
  }

  const { loading: boot } = useSelector(state => state.bootApp);

  const homeCMSTop = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione home sopra al bottone partecipa',
  )?.contents;

  const homeCMSBottom = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione home sotto al bottone partecipa',
  )?.contents;
  const progressBarCms = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Progress Bar',
  )?.contents;
  return (
    <>
      {boot ? (
        <Loader show={boot} />
      ) : (
        <Fade>
          <Wrapper>
            {homeCMSTop?.map((c, k) => {
              return <CmsComponent key={k} content={c} />;
            })}
            <ProgressBar contentSteps={progressBarCms} step={1} />
            <div ref={scrollRef} style={{ padding: '20px 0' }}>
              {games[0] && (
                <Button active style={{ margin: '0 auto' }}>
                  <Link to={`/promo/${slug}/game/${games[0]?.id}`}>PARTECIPA ORA</Link>
                </Button>
              )}
            </div>
            <Flex justify='space-between'>
              {homeCMSBottom?.map((c, i, arr) => {
                return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
              })}
            </Flex>
          </Wrapper>
        </Fade>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    islogged: state.auth.token,

    hgpromo: state.promo.hgpromo,
    errorhgpromo: state.promo.errorhgpromo,
    errorallpromo: state.promo.errorallpromo,
    allpromos: state.promo.allpromo,
    loadinghgpromo: state.promo.loadinghgpromo,
    loadingallpromo: state.promo.loadingallpromo,
    appConfig: state.app.config,

    error404: state.promo.error404,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllPromo: () => dispatch(actionCreators.getAllPromo()),
    getHgPromo: () => dispatch(actionCreators.getHighlightsPromo()),
    getPromoTag: (slugtag, cid) => dispatch(actionCreators.getPromoTag(slugtag, cid)),

    reset404error: () => dispatch(actionCreators.reset404error()),
  };
};

Home.propTypes = {
  //viewerLoading: PropTypes.bool,
  // viewer: PropTypes.any,
  //getViewer: PropTypes.func,
  getAllPromo: PropTypes.func,
  getHgPromo: PropTypes.func,
  loadinghgpromo: PropTypes.bool,
  loadingallpromo: PropTypes.bool,
  loadingclienttags: PropTypes.bool,
  hgpromo: PropTypes.array,
  allpromos: PropTypes.array,
  tagsclient: PropTypes.array,
  appConfig: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
