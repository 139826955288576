import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

const Wrapper = styled.div`
  max-width: ${props =>
    props.theme.containerWidthFull ? '100%' : props.theme.containerWidth + 'px'};
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export default Wrapper;
