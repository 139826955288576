import React from 'react';

const ArrowProgress = ({ color }) => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' viewBox='0 0 16 18'>
        <path
          id='Icon_awesome-arrow-right'
          data-name='Icon awesome-arrow-right'
          d='M9.963,3.853l.951-.915a1.053,1.053,0,0,1,1.453,0L20.7,10.947a.959.959,0,0,1,0,1.4l-8.331,8.013a1.053,1.053,0,0,1-1.453,0l-.951-.915a.964.964,0,0,1,.017-1.414L15.144,13.3H6.028A1.007,1.007,0,0,1,5,12.307V10.988A1.007,1.007,0,0,1,6.028,10h9.116L9.981,5.267A.957.957,0,0,1,9.963,3.853Z'
          transform='translate(-5 -2.647)'
          fill={color}
        />
      </svg>
    </>
  );
};

export default ArrowProgress;
