import React from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Wrapper } from '../Ui';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { respondTo } from '../../theme/mixin';

const FooterContainer = styled.footer`
  margin-top: 30px;
  border-top: ${({ theme }) => theme.footerBorderTop};
  background-color: ${({ theme }) => theme.footer_bg};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande}
    ${({ theme }) => theme.border_radius_generale_grande} 0 0;
  overflow: hidden;
  position: relative;
  padding: 0 20px;
  .logoLeft {
    max-width: 185px;
  }

  .logoRight {
    max-width: 85px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  .container {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    ${respondTo.sm`
    justify-content: space-between;
    flex-direction: row;
  
    `};
  }
  ${respondTo.sm`
    max-width: ${({ theme }) => theme.containerWidth}px;
    margin: auto
    `};
`;

const FooterComponent = ({ component, className }) => {
  let componentImg = null;
  let componentContent = null;
  let componentTitle = null;
  if (component.img) {
    componentImg = <img src={component?.img} alt='' className={className} />;
  }
  if (component?.i18l.content && component?.i18l.content !== '<p>&nbsp;</p>') {
    componentContent = (
      <Text
        as='p'
        size={18}
        color={component?.text_color || component?.default_color}
        className='textWhite'
      >
        {component?.i18l.content}
      </Text>
    );
  }
  if (component?.i18l.title && component?.i18l.title !== '') {
    componentTitle = (
      <Text
        as='p'
        size={25}
        color={component?.text_color || component?.default_color}
        className='textWhite'
      >
        {component?.i18l.title}
      </Text>
    );
  }
  return (
    <div className='component'>
      {componentImg}
      {componentTitle}
      {componentContent}
    </div>
  );
};

export default FooterComponent;
