import customaxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

export const getCmsBase = () => {
  let endpoint = `cms/base`;
  return dispatch => {
    dispatch(getCmsBaseStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsBaseSuccess(result.data));
          resolve();
        })
        .catch(error => {
          dispatch(getCmsBaseError(error));
          reject(error);
        });
    });
  };
};

const getCmsBaseStart = () => {
  return {
    type: actionTypes.GET_CMS_BASE_START,
  };
};

const getCmsBaseSuccess = content => {
  return {
    type: actionTypes.GET_CMS_BASE_SUCCESS,
    content,
  };
};

const getCmsBaseError = error => {
  return {
    type: actionTypes.GET_CMS_BASE_ERROR,
    error,
  };
};
