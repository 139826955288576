import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';
import styled from 'styled-components';
import { deltaDate } from '../../../../utils/utils';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';

registerLocale('it', it);
const InputWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  flex-grow: 1;
`;

const DatepickerCustom = styled(DatePicker)`
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.border};
  width: 100%;
  padding: 0 15px;
  color: ${props => props.theme.colore_testo_campi};
  background-color: ${props => props.theme.input.bg};
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  /*  font-weight: 700; */
  outline: none;

  &::placeholder {
    color: ${props => props.theme.primary};
    text-transform: uppercase;
  }
`;

const Datepicker = props => {
  const {
    elementConfig: { placeholder },
    validation: { maxDate, minDate, minTime, maxTime },
  } = props;

  let dateFormat = 'dd/MM/yyyy';

  if (props.lang === 'it') {
    dateFormat = 'dd/MM/yyyy';
  }

  const onkeydown = e => {
    if (e.keyCode === 8) {
      e.preventDefault();
      return false;
    }
  };

  /*   const maxdate = props.own_login_adult
    ? deltaDate(new Date(), 0, 0, -18)
    : maxDate === 'today'
    ? new Date()
    : maxDate; */

  return (
    <InputWrap className='datepicker'>
      <DatepickerCustom
        autoComplete='off'
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        locale='it'
        onKeyDown={onkeydown}
        showMonthDropdown
        showYearDropdown
        minTime={minTime}
        maxTime={maxTime}
        dropdownMode='select'
        placeholderText={placeholder}
        {...props}
      />
    </InputWrap>
  );
};

export default Datepicker;
