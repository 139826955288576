import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loadingBase: true,
  errorBase: false,
  footer: [],

  base: [],
  authentication: [],
  profile: [],
  contact: [],
  home: [],
  register: [],

  game: [],
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_BASE_START:
      return {
        ...state,
        loadingBase: true,
      };
    case actionTypes.GET_CMS_BASE_SUCCESS:
      return {
        ...state,
        loadingBase: false,
        base: action.content,
      };
    case actionTypes.GET_CMS_BASE_ERROR:
      return {
        ...state,
        loadingBase: false,
        errorBase: action.error,
      };
    case actionTypes.GET_CMS_FOOTER_START:
      return {
        ...state,
        loadingfooter: true,
      };
    case actionTypes.GET_CMS_FOOTER_SUCCESS:
      return {
        ...state,
        loadingfooter: false,
        footer: action.content,
      };
    case actionTypes.GET_CMS_FOOTER_ERROR:
      return {
        ...state,
        loadingfooter: false,
        errorfooter: action.error,
      };

    case actionTypes.GET_CMS_HOME_SUCCESS:
      return {
        ...state,
        home: action.content,
      };
    case actionTypes.GET_CMS_REGISTER_SUCCESS:
      return {
        ...state,
        register: action.content,
      };

    case actionTypes.GET_CMS_GAME_SUCCESS:
      return {
        ...state,
        game: action.content,
      };

    default:
      return state;
  }
};

export default cmsReducer;
