import * as actionTypes from './actionTypes';
import { appInit } from './app';
import { checkAuth, checkAuthState } from './auth';
import { getCmsBase } from './cms';

const bootAppStart = () => {
  return {
    type: actionTypes.BOOT_APP_START,
  };
};

const bootAppSuccess = () => {
  return {
    type: actionTypes.BOOT_APP_SUCCESS,
  };
};

const bootAppError = error => {
  return {
    type: actionTypes.BOOT_APP_ERROR,
    error,
  };
};

export const bootApp = () => {
  return dispatch => {
    dispatch(bootAppStart());
    Promise.all([
      dispatch(appInit()),
      dispatch(checkAuth()),
      dispatch(checkAuthState()),
      dispatch(getCmsBase()),
    ])
      .then(() => {
        dispatch(bootAppSuccess());
      })
      .catch(error => {
        dispatch(bootAppError(error.response?.status));
      });
  };
};

export const bootCms = (loader = false) => {
  return dispatch => {
    if (loader) {
      dispatch(bootAppStart());
    }
    Promise.all([dispatch(getCmsBase())]).then(() => {
      if (loader) {
        dispatch(bootAppSuccess());
      }
    });
  };
};
