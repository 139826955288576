import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { signinform } from '../../formsConfig/formsConfig';
import { Button, Input, Modal, Loader, Flex, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import { MoreOauths } from '../Oauths';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { getSlug } from '../../utils/utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';
import styled from 'styled-components';
import { remember, showAuthForm } from '../../store/actions/auth';

const Btns = styled.div`
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  label {
    color: ${({ theme }) => theme.colore_testo_box} !important;
  }
  .title,
  .subtitle {
    text-align: center;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
`;
const CustomButton = styled(Button)`
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SigninForm = props => {
  const {
    inModal,
    error,
    clearError,
    signinInit,
    loading,
    redirect,
    whereToGo,
    oauths,
    oauthInfo,
    isOauth,
    oauthSendToken,
    visible,
    username,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const inputRef = useRef();
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  const registerCMS = useSelector(state => state.cms.base).find(c => c.name === 'Testi generali');

  const titleFormLogin = registerCMS?.contents?.find(c => c.name === 'Intestazione Login')?.i18l
    ?.title;
  const subtitleFormLogin = registerCMS?.contents?.find(c => c.name === 'Intestazione Login')?.i18l
    ?.content;
  const titleButtonLogin =
    registerCMS?.contents?.find(c => c.name === 'Testo Pulsante Login')?.i18l?.title ||
    registerCMS?.contents?.find(c => c.name === 'Testo Pulsante Login')?.i18l?.content ||
    'ACCEDI';
  const titleButtonRecoveryPw =
    registerCMS?.contents?.find(c => c.name === 'Testo pulsante recupera password')?.i18l?.title ||
    registerCMS?.contents?.find(c => c.name === 'Testo pulsante recupera password')?.i18l
      ?.content ||
    'RECUPERA PASSWORD';
  const signInForm = signinform(t('auth.email'), t('auth.password'), inputRef, inModal);
  t();
  const dispatch = useDispatch();
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    if (error) {
      setShowModal(true);
      dispatch(showAuthForm(true));
    }

    // return () => {};
  }, [error]);

  const login = () => {
    localStorage.setItem('exp', formData.recordami.value);
    let usernameType = null;
    switch (username) {
      case 'email':
        usernameType = formData.email.value;
        break;
      case 'vatCode':
        usernameType = formData.vatcode.value;
        break;
      case 'external_id':
        usernameType = formData.external_id.value;
        break;
      default:
        break;
    }
    signinInit(usernameType, formData.password.value).then(() => {
      error ? dispatch(showAuthForm(true)) : dispatch(showAuthForm(false));
      // dispatch(actionCreators.getViewer());
    });
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit, setFormData } = useForm(
    login,
    signInForm,
  );

  if (username === 'vatCode') {
    delete formData.email;
    delete formData.external_id;
  } else if (username === 'email') {
    delete formData.vatcode;
    delete formData.external_id;
  } else if (username === 'external_id') {
    delete formData.email;
    delete formData.vatcode;
  }

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        form={formData}
        setForm={setFormData}
        {...inp}
      />
    );
  });

  let oauthsContent = null;
  if (oauths !== undefined) {
    if (oauths.length > 0) {
      oauthsContent = (
        <MoreOauths
          oauths={oauths}
          oauthInfo={oauthInfo}
          inModal={inModal}
          whereToGo={whereToGo}
          isOauth={isOauth}
          oauthSendToken={oauthSendToken}
          lastLocation={lastLocation}
          redirect={redirect}
        />
      );
    }
  }

  if (redirect) {
    let to = whereToGo;
    if (lastLocation !== null) {
      if (
        lastLocation.pathname.split('/')[1] === 'activate' ||
        lastLocation.pathname.split('/')[1] === 'reset-password' ||
        lastLocation.pathname.split('/')[1] === 'auth'
      ) {
        to = '/';
      } else {
        to = lastLocation.pathname;
      }
    }
    if (inModal) {
      to = `/${getSlug()}`;
    }
    return <Redirect to={to} />;
  } else {
    return (
      <Container>
        <Text as='div' size='40' bold className='title' margin='40px auto'>
          {titleFormLogin}
        </Text>
        <Text as='div' margin='10px auto'>
          {subtitleFormLogin}
        </Text>

        <form onSubmit={formSubmitHandler}>
          <AuthContentWrapper title=''>
            {inputs}
            <Flex justify='center' style={{ marginTop: '20px' }}>
              <Button
                white
                active
                upper
                bold
                type='submit'
                loading={loading}
                width='48%'
                style={{ justifyContent: 'center' }}
              >
                {titleButtonLogin}
              </Button>
            </Flex>
          </AuthContentWrapper>
        </form>

        <Flex justify='center' style={{ margin: '20px 0' }}>
          <Link to='/auth/recovery-password'>
            <Text bold upper>
              {titleButtonRecoveryPw}
            </Text>
          </Link>
        </Flex>
        <Flex justify='center' direction='column' align='center'>
          <Text align={'center'} bold margin='100px 0 10px 0'>
            NUOVO UTENTE? REGISTRATI ORA!
          </Text>
          <Button fontSize='14px' secondary upper bold style={{ justifyContent: 'center' }}>
            <Link to='/auth/register'>Registrati</Link>{' '}
          </Button>
        </Flex>

        <Modal alert show={showModal} close={modalHandler} type='error'>
          <ErrorInModal error={error} />
        </Modal>
      </Container>
    );
  }
};

SigninForm.defaultProps = {
  inModal: false,
};

SigninForm.propTypes = {
  inModal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.number,
  redirect: PropTypes.bool,
  whereToGo: PropTypes.string,
  clearError: PropTypes.func,
  signinInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    redirect: state.auth.redirect,
    error: state.auth.errorsignin,
    loading: state.auth.loadingsignin,
    oauths: state.app.config.oauths,
    visible: state.app.config.visible,
    oauthInfo: state.app.oauthInfo,
    isOauth: state.auth.isOauth,
    username: state.app.config.ownlogin.username,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signinInit: (email, password, recordami) =>
      dispatch(actionCreators.signinInit(email, password, recordami)),
    clearError: () => dispatch(actionCreators.signinClearError()),
    oauthSendToken: (code, connect, data) =>
      dispatch(actionCreators.oauthSendToken(code, connect, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
