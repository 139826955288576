import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import { useSelector } from 'react-redux';
import Text from '../Text';

const LogoContainer = styled.div`
  //max-width: ${props => (props.theme.logo_size ? props.theme.logo_size + 'px' : 'auto')};
  margin-right: 0;
  align-self: center;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'auto')};
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    outline: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
    ${respondTo.sm`
     justify-content: initial;
      gap: 15px;
  `};
  }
  ${respondTo.sm`
       margin-right: 60px;
       margin-top:0;
       margin-bottom:0;
  `};
`;

const Logo = props => {
  const { nolink, logo, logo_link, logo_link_target } = props;
  const homeData = useSelector(state => state.cms.home);
  // const logoCms = homeData[0]?.contents.find(el => el.name === 'logo');
  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <a href={logo_link} target={logo_link_target}>
        <img src={logo} alt='' />
        {/* {logoCms?.i18l?.content && <Text>{logoCms?.i18l?.content}</Text>} */}
      </a>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
