import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { respondTo } from '../../../../theme/mixin';

const LinkElem = styled(NavLink)`
  ${respondTo.sm`
   display: flex;
   height: 100%;
    text-decoration: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index:1; 
  `};

  span {
    display: block;
    transition: 0.4s;
    color: ${({ theme }) => theme.colore_testo_menu};
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    ${respondTo.sm`
       margin-top: 0;
       margin-bottom: 0;
  `};
  }
  svg,
  img {
    color: ${({ theme }) => theme.colore_testo_menu};
    display: none;
    ${respondTo.sm`
      display:block;   
  `};
  }
  img {
    width: 55px;
    max-width: initial;
  }
  .numWish {
    color: ${({ theme }) => theme.colore_testo_menu};
  }
  &.active,
  &:hover {
    background-color: ${({ theme }) => theme.background_testo_menu_attivo};
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.colore_testo_menu_attivo};
    }
    ${respondTo.sm`
      
      width: auto;
    `};
  }
`;

const NavigationItem = props => {
  const { link, children } = props;

  return (
    <li style={{ position: 'relative' }}>
      <LinkElem exact to={link} {...props}>
        {children}
      </LinkElem>
    </li>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

export default NavigationItem;
