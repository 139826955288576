import React from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Wrapper } from '../Ui';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { respondTo } from '../../theme/mixin';
import FooterComponent from './FooterComponent';
const FooterContainer = styled.footer`
  margin-top: 30px;
  border-top: ${({ theme }) => theme.footerBorderTop};
  background-color: ${({ theme }) => theme.footer_bg};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande}
    ${({ theme }) => theme.border_radius_generale_grande} 0 0;
  overflow: hidden;
  position: relative;
  padding: 0 20px;
  .logoLeft {
    max-width: 185px;
  }

  .logoRight {
    max-width: 85px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  .container {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    ${respondTo.sm`
    justify-content: space-between;
    flex-direction: row;
  
    `};
  }
  ${respondTo.sm`
    max-width: ${({ theme }) => theme.containerWidth}px;
    margin: auto
    `};
`;

const Footer = props => {
  const footercontent = useSelector(state => state.cms.base).find(c => c.name === 'Footer');

  const footerTopCenter = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in alto al centro',
  );
  const footerBottomLeft = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in basso a sinistra',
  );
  const footerBottomCenter = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in basso al centro',
  );
  const footerBottomRight = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in basso a destra',
  );
  const footerTopLeft = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in alto a sinistra',
  );
  const footerTopRight = footercontent?.contents?.find(
    el => el.name === 'Sezione footer in alto a destra',
  );

  return (
    <div style={{ padding: 0, position: 'relative' }}>
      <FooterContainer>
        <Wrapper>
          <Container>
            <Flex align='center' justify='space-between' className='container'>
              {footerTopLeft && <FooterComponent component={footerTopLeft} className='logoLeft' />}
              {footerTopCenter && <FooterComponent component={footerTopCenter} />}
              {footerTopRight && (
                <FooterComponent component={footerTopRight} className='logoRight' />
              )}
            </Flex>
          </Container>
          <Container>
            <Flex align='center' justify='space-between' className='container'>
              {footerBottomLeft && <FooterComponent component={footerBottomLeft} />}
              {footerBottomCenter && <FooterComponent component={footerBottomCenter} />}
              {footerBottomRight && <FooterComponent component={footerBottomRight} />}
            </Flex>
          </Container>
        </Wrapper>
      </FooterContainer>
    </div>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
