import React from 'react';

const Plus = ({ color }) => {
  return (
    <>
      <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='1'
          y='1'
          width='18'
          height='18'
          stroke='black'
          stroke-width='2'
          fill='none'
          stroke-dasharray='4,4'
        />

        <rect x='9' y='4' width='2' height='12' fill={color} />
        <rect x='4' y='9' width='12' height='2' fill={color} />
      </svg>
    </>
  );
};

export default Plus;
