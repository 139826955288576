import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Notification from '../../components/HomeBlocks/Notification';
import ProgressBar from '../../components/ProgressBar';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import SigninForm from '../../components/SigninForm/SigninForm';
import { Flex, Text, Wrapper } from '../../components/Ui';
import customAxiosNm from '../../config/axios-refresh-token';
import useQuery from '../../hooks/useQuery';
import { appQuery } from '../../store/actions/app';
import { showAuthForm } from '../../store/actions/auth';
import { respondTo } from '../../theme/mixin';
import CmsComponent from '../../components/CmsComponent';
const AuthTemplate = props => {
  // const [isLogin, setIsLogin] = useState(false);

  const { from } = props.location.state || { from: '/' };
  const isLogin = useSelector(state => state.auth.show_auth_form);
  const dispatch = useDispatch();
  const { auth, token } = useParams();
  const query = useQuery();
  const queryApp = query.get('app');
  const open = query.get('open');
  // const token = query.get('token');
  const appQueryParam = useSelector(state => state.app?.appQuery);
  const visible = useSelector(state => state.app?.confg?.visible);
  const history = useHistory();
  const [registerData, setRegisterData] = useState(null);

  const registerCMSTopData = useSelector(state => state.cms.base).find(
    item => item.name === 'Sezione da non loggato sopra al form',
  )?.contents;

  const registerCMSBottomData = useSelector(state => state.cms.base).find(
    item => item.name === 'Sezione da non loggato sotto al form',
  )?.contents;
  const progressBarCms = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Progress Bar',
  )?.contents;
  const scrollRef = useRef();
  useEffect(() => {
    history.location.pathname === '/auth/register' && dispatch(showAuthForm(true));
    if (!token) return;
    customAxiosNm
      .get(`/auth/externaltokendecode?token=${token}`)
      .then(response => {
        setRegisterData(response.data);
        dispatch(showAuthForm(true));
      })
      .catch(error => {});
  }, []);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');

    if (queryApp || localQueryApp) {
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
      localStorage.setItem('queryApp', true);
    }
  }, [queryApp]);

  useEffect(() => {
    visible && history.location.pathname === '/auth/register' && history.push('/auth/login');
  }, [visible, history.location.pathname]);

  return (
    <CustomWrapper>
      <div>
        {!appQueryParam && <Header />}
        <SidesWrapper>
          {registerCMSTopData?.map((c, k) => {
            return <CmsComponent key={k} content={c} />;
          })}

          <ProgressBar contentSteps={progressBarCms} step={0} />
          <FormContainer visible={visible && history.location.pathname === '/auth/register'}>
            <Route path='/auth/login' exact>
              <SigninForm whereToGo={from} />
            </Route>

            <Route path={token ? '/auth/register/:token*' : '/auth/register'} exact>
              <RegisterForm data={registerData} />
            </Route>

            <Route path='/auth/recovery-password' exact>
              <RecoveryPasswordForm />
            </Route>
            {props.children}
          </FormContainer>
          <Flex justify='space-between'>
            {registerCMSBottomData?.map((c, i, arr) => {
              return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
            })}
          </Flex>
        </SidesWrapper>
      </div>
      {!appQueryParam && <Footer padding={0} />}
    </CustomWrapper>
  );
};

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SidesWrapper = styled(Wrapper)`
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  position: relative;

  .banner {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg_box};
  flex: 0 0 100%;
  padding: 30px 15px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999999999;

  ${respondTo.sm`
    flex: 0 0 60%;
  `};
`;

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(AuthTemplate);
