import React, { useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import * as allStyles from '../../theme/theme';
import ArrowProgress from '../Ui/Icons/ArrowProgress';
import { Text } from '../Ui';
const ProgressBarContainer = styled.div`
  
  background-color: ${props => props.background ? props.background : ''};
  padding: 25px;
  position: relative;
  
`;

const ProgressBarTitle = styled.div`
  text-align: center;
  margin-bottom: 17px;
  margin-top: 5px;
`;
const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  max-width: ${props => props.theme.containerWidth ? props.theme.containerWidth +'px' : '100%'};
  margin: auto;
  flex-wrap: wrap;
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  background: ${props => (props.completed ? props.theme.background_step_attivo_progress_bar : props.background ? props.background : '')};;
  height: 100%;
  padding: 0 20px;
  gap: 10px;
  min-height: 50px;
  font-weight: bold;
  * {
    color: ${props => (props.completed ? props.theme.colore_testo_step_attivo_progress_bar : props.color ? props.color : '')};
  }
`;

const StepLabel = styled.span`
  font-size: 12px;
  text-align: center;
  ${respondTo.sm`
  font-size: 16px;
  
  `}
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

const ProgressBar = ({ step, contentSteps }) => {
  const title = contentSteps?.find(
    item => item.name === "Titolo progress bar",
  );
  const steps = contentSteps?.filter(
    item => item.name !== "Titolo progress bar",
  );
  return (
    <ProgressBarContainer background={title?.background_color}>
      <ProgressBarTitle >
        <Text color={title?.text_color} bold size={36}>{title?.i18l?.title ? title?.i18l?.title : title?.i18l?.content}</Text>
      </ProgressBarTitle>
      <StepsContainer>
      {steps?.map((content, index) => (
        <StepWrapper key={index}>
          <Step completed={index == step} background={content?.background_color} color={content?.text_color}>
            <ArrowProgress color={index == step ? allStyles.theme.colore_testo_step_attivo_progress_bar : content?.text_color}/>
            <Text bold size={34}>{index + 1}</Text>
            <StepLabel>
              {(content?.i18l?.title || content?.i18l?.content) && <Text>{content?.i18l?.title ? content?.i18l?.title : content?.i18l?.content}</Text>}
            </StepLabel>
          </Step>
        </StepWrapper>
      ))}
      </StepsContainer>
      
    </ProgressBarContainer>
  );
};

export default ProgressBar;
