import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../..';

const GoToGame = props => {
  const [showModal, setShowModal] = useState(false);
  const [t] = useTranslation();
  const {
    isLogged,
    logo,
    url,
    cta,
    ctatype,
    oauths,
    ownlogin,
    oauthinfo,
    idgame,
    pathname,
    multipromo,
  } = props;

  useEffect(() => {
    if (isLogged) {
      setShowModal(false);
    }
  }, [isLogged]);

  const closeModalHandler = () => {
    setShowModal(false);
  };

  let content = null;

  if (ctatype === 'url') {
    content = (
      <a href={url} target='_blank'>
        <Button upper>{cta ? cta : t('app.visitsite')}</Button>
      </a>
    );
  } else {
    content =
      isLogged && ctatype === 'game' ? (
        <Link
          to={{
            pathname: multipromo ? `${pathname}/game/${idgame}` : `/promo/game/${idgame}`,
            state: { cid: 'boxgotogame-viewer' },
          }}
        >
          <Button upper>{cta ? cta : t('app.play')}</Button>
        </Link>
      ) : (
        ownlogin && (
          <Link to='/auth/login'>
            <Button upper>{cta ? cta : t('app.play')}</Button>
          </Link>
        )
      ); /*  : (
				<LoginBtn
					label={cta ? cta : t("app.play")}
					ownlogin={ownlogin}
					oauths={oauths}
					oauthinfo={oauthinfo}
				/>
			); */
  }

  return (
    <React.Fragment>
      {content}
      {/* <Modal show={showModal} close={closeModalHandler}>
				<ModalLoginFlow logo={logo} />
			</Modal> */}
    </React.Fragment>
  );
};

GoToGame.propTypes = {
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  ctatype: PropTypes.string,
};

export default React.memo(GoToGame);
