import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Flex, Input, Modal, Text, Wrapper } from '../../components/Ui';
import useForm from '../../hooks/useForm';
import { voucherForm } from '../../formsConfig/formsConfig';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customAxiosNm from '../../config/axios-refresh-token';
import CmsComponent from '../../components/CmsComponent';
import Notification from '../../components/HomeBlocks/Notification';
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  hr {
    width: 100%;
    display: block;

    ${respondTo.sm`
    width: 204%;
    transform: translateX(-25%);
  `}
  }
  .btns {
    button {
      width: 50%;
      justify-content: center;
      margin: 15px auto;
    }
  }
  ${respondTo.sm`
width: 50%;
  `}
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;
const Container = styled.div`
  counter-reset: form;
  .datepicker {
    background-color: transparent;
  }
  .content {
    margin: 50px 15px;
    ${respondTo.sm`
    margin: 50px 60px
    `};

    ul {
      list-style-type: disc;
    }
  }
  input,
  select {
    width: 100%;
    padding: 10px;
    outline: none;
    border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};
    /* border: 1px solid #c5c3c3; */
    font-size: 16px;
    font-family: ${props => props.theme.fontFamily};
  }
  input:read-only {
    background-color: #dddddd;
    border: 1px solid #c5c3c3;
  }
  .flexInfo {
    align-items: flex-end;
    gap: 10px;
  }
  .flexInfo::before {
    counter-increment: form;
    content: '' counter(form) '. ';
    height: 50px;
    width: 50px;
    background: ${({ theme }) => theme.bg_bottoni || theme.primary};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    min-width: 50px;
  }
  .lable {
    font-weight: normal;
    font-size: 16px;
  }
`;

const List = styled.ul`
  display: block;
  list-style-type: disc;
  list-style-position: inside;
  margin: 20px 0;
`;

const Voucher = () => {
  const location = useLocation();
  const history = useHistory();
  const { voucher_printable_to, voucher_printable_from } = location.state || {};

  const [province, setProvince] = useState([]);
  const [loading, setLoading] = useState(false);
  const [daye, setDate] = useState();

  const [error, setError] = useState();
  const { id: useId, firstname, lastname } = useSelector(state => state.user.user.userinfo || {});
  const formVoucher = voucherForm({ voucher_printable_from, voucher_printable_to });

  const { id } = useParams();

  const assegnaBuoniSopra = useSelector(state => state.cms.base).find(
    item => item.name === 'Sezione Voucher sopra il form',
  )?.contents;
  const assegnaBuoniSotto = useSelector(state => state.cms.base).find(
    item => item.name === 'Sezione Voucher sotto il form',
  )?.contents;
  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  useEffect(() => {
    customAxiosNm
      .get(`qmi/cinema/getprovinces`, {
        params: {
          pah_promo: localStorage.getItem('pahPromo'),
        },
      })
      .then(res => setProvince(res.data.Results.provinces));
  }, []);

  const submitHandler = data => {
    setLoading(true);
    customAxiosNm
      .post('qmi/voucher/setvoucher', {
        pah_code: id,
        pah_promo: localStorage.getItem('pahPromo'),
        viewer_name: firstname,
        viewer_surname: lastname,
        film_title: '',
        ...data,
      })
      .then(res => {
        setLoading(false);
        if (res.data.Status.Success) {
          history.push('/i-miei-scontrini');
        } else {
          setError(res.data.Status.Message);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  };

  let datePickerNames = [];

  for (var prop in formVoucher) {
    if (formVoucher[prop].elementType === 'datepicker') {
      datePickerNames.push(formVoucher[prop].elementConfig.name);
    }
  }
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    resetFormHandler,
    startDate,
    datePickerHandler,
    cinemas,
  } = useForm(submitHandler, formVoucher, datePickerNames);
  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        showError={showError}
        startdate={startDate}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        resetFormHandler={resetFormHandler}
        province={province || []}
        cinemas={cinemas}
        {...inp}
      />
    );
  });

  return (
    <>
      {assegnaBuoniSopra?.map((c, k) => {
        return <CmsComponent key={k} content={c} />;
      })}
      <Wrapper>
        <Container ref={scrollRef}>
          <Flex
            gap={15}
            style={{ paddingBottom: '15px', borderBottom: '1px solid #ddd', marginBottom: '30px' }}
            row={15}
          >
            <Col width={50}>
              <input type='text' readOnly value={firstname} />
            </Col>
            <Col width={50}>
              <input type='text' readOnly value={lastname} />
            </Col>
          </Flex>

          <Form onSubmit={formSubmitHandler}>
            {inputs}
            <hr />
            <div className='btns'>
              <Button type='submit' active disabled={loading} loading={loading}>
                GENERA
              </Button>

              <Button type='button' secondary onClick={() => window.history.back()}>
                INDIETRO
              </Button>
            </div>
          </Form>
        </Container>
      </Wrapper>
      <Flex justify='space-between'>
        {assegnaBuoniSotto?.map((c, i, arr) => {
          return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
        })}
      </Flex>
      <Modal alert show={Boolean(error)} close={() => setError('')} type='error'>
        {error}
      </Modal>
    </>
  );
};

export default Voucher;
